
.ShoppingList {
  text-align: left;
}

.ShoppingList-header {
  /* background-color: #282c34; */
  background-color: #5895d3;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(44, 65, 129);
}


.ShoppingList .listItem {
  /* padding: 14pt; */
  border: 0.5pt solid black;
  /* border: 1pt red solid; */
  /* border: 1pt rgb(60, 60, 60) solid; */
  width: 95%;
}

.ShoppingList .listItem input {
  padding: 0 0 0 10px; 
}

.ShoppingList .listItem div div #qtyUnit {
  /* padding: 0 0 0 10px;  */
  margin-top: -4px;
}
.ShoppingList .listItem div label#labelqtyUnit {
  margin-top: 7px;
}
.ShoppingList .listItem div label::first-letter {
    text-transform:capitalize;
}

.ShoppingList .listItemsBox {
  /* color: green; */
  font-size: 12pt;
  /* border: 2pt yellow solid; */
  /* min-width: 300pt; */
  /* height: 300px; */
  text-align: left;
  background-color: whitesmoke;
  overflow-y: scroll;
}

.ShoppingList .listItemsBox ul {
  list-style-type: none;
  padding-left: 0rem;
}

.ShoppingList .listItemsBox ul li div {
  color: rgb(116, 116, 116);
  padding: 5pt 0pt 0pt 0pt;
  float: left;
  /* min-height: 22pt; */
  margin: 0 2pt 0 2pt;
  /* color: black; */
  background-color: rgb(237, 230, 230);
  border: 5pt solid black;
  /* font-size: 200pt */
}
.ShoppingList .editItemsBox {
  /* color: rgb(116, 116, 116); */
  color: rgb(219, 216, 24);
  padding: 5pt 0pt 0pt 0pt;
  /* float: left; */
  /* min-height: 22pt; */
  margin: 0 2pt 0 2pt;
  /* color: black; */
  background-color: rgb(237, 230, 230);
  /* border: 0.5pt solid black; */
  /* font-size: 200pt */
}

.ShoppingList .two-colum-list {
  display: grid;
  grid-template-columns: 50% 50%;
}

@media only screen and (max-width: 600px) {
.ShoppingList .two-colum-list {
  display: grid;
  grid-template-columns: 100%;
}}
