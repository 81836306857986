.modal {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  /* display: block; */
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
}

.modal.enter-done {
  opacity: 1;
  pointer-events: visible;
}

.modal.exit {
  opacity: 0;
}

/* .modal .modal-form {
  background-color: rgb(151, 81, 81);
} */

.modal-content {
  width: 700px;
  background-color: rgb(167, 159, 159);
  transition: all 0.3s ease-in-out;
  transform: translateY(-200px);
}

.modal.enter-done .modal-content {
  transform: translateY(0);
}

.modal.exit .modal-content {
  transform: translateY(-200px);
}

.modal-header,
.modal-footer {
  padding: 10px;
}

.modal-title {
  margin: 0;
}

.modal-body {
  padding: 10px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.modal input {
  display: block;
}
.modal input[type="text"] {
  max-width: 100%;
  width: 100%;
}
.modal textarea {
  display: block;
}

/* .modal label {
  font-size: x-small;
  display: block;
} */