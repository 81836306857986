
h1 {
  color: #ccc;
  font-size: 2rem;
}

.myClass {
  color:antiquewhite;
  font-size: 2rem;
}
