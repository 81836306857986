.list {
  text-align: left;
  /* max-width: 750px; */
  margin: auto;
}
.list a:hover {
  color: rgb(33, 37, 41);
}
.list a {
  text-decoration: none;
  color: rgb(33, 37, 41);
}

.card {
  /* width: 18rem; */
  width: 24rem;
  margin: 10px auto;
}

.submit-form {
  max-width: 500px;
  margin: auto;
}
.edit-form {
  max-width: 700px;
  margin: auto;
}

.footer {
  text-align: center;
}
