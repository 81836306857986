.Recipes form {
  /* width: 200px; */
  background-color: aquamarine;
}

.Recipes input {
  display: block;
  /* width: 100%; */
}

.Recipes input[type="text"] {
  max-width: 100%;
}


.Recipes textarea {
  display: block;
}

.Recipes .left {
  float: left;
}

.Recipes .right {
  float: right;
}