.list {
  text-align: left;
  /* max-width: 750px; */
  margin: auto;
}
.list a:hover {
  color: rgb(33, 37, 41);
}
.list a {
  text-decoration: none;
  color: rgb(33, 37, 41);
}

.card {
  /* width: 18rem; */
  width: 24rem;
  margin: 10px auto;
}

.submit-form {
  max-width: 500px;
  margin: auto;
}
.edit-form {
  max-width: 500px;
  margin: auto;
}

.footer {
  text-align: center;
}

.Accordion .accordion-body {
  padding: 5pt 10pt 0pt 10pt;
  /* width: 110%; */
}

.Accordion {
  --bs-accordion-btn-padding-x: 1.0rem;
  --bs-accordion-btn-padding-y: 0.5rem;
  width: 108%;
  position: relative;
  left: -12pt;
}

.shrinkToFitImage {
  max-height: 10rem;
  width: 100%;
  overflow: hidden;
}
/* .card-img-top {
  width: 100%;
  width: inherit;
  min-width: 100%;
  height: 18rem;
} */