
.MenuPlanning {
  background-color: #c0c0c0;
  background-image: url("../img/patterns/cubes.png");
  /* covers the area with ONE tile */
  /* background-size: cover; */
  /* please no, we do NOT want a scroll-bar to appear */
  /* overflow-y: auto; */
  margin: 0;
  /* padding: 0 16px; */
  padding: 0;
}

.MenuPlanning header {
  background-color: #f5ba13;
  /* margin: auto -16px; */
  padding: 16px 32px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
}

.MenuPlanning header h1 {
  color: #fff;
  font-family: "McLaren", cursive;
  font-weight: 200;
}

.MenuPlanning footer {
  position: sticky;
  text-align: center;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
}

.MenuPlanning footer p {
  color: #ccc;
}

.MenuPlanning .jumbotron {
  text-align: center;
  background-color: #efcb6a;
  border-radius: 5px;
  /* width: 90%; */
  /* width: 100%; */
  margin: 0px 20px;
  /* padding: 0px 20px; */
  /* padding-top: 20px; */
}

.MenuPlanning .MenuCard {
  background: #fff;
  border-radius: 7px;
  box-shadow: 0 2px 5px #ccc;
  padding: 10px;
  min-width: 240px;
  width: 100%;
  /* max-width: 440px; */
  margin: 16px;
  float: left;
  white-space: nowrap;
}
.MenuPlanning .MenuCard h1 {
  font-size: 1.1em;
  margin-bottom: 6px;
}
.MenuPlanning .MenuCard p {
  font-size: 1.1em;
  margin-bottom: 10px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.MenuPlanning .MenuCards {
  /* color: #ccc; */
  color: #888;
  margin: 0 20px;
  /* margin: 0 auto;
  width: 90%; */
}
.MenuPlanning .MenuCards:after {
  content:"";
  clear:both;
  display:block;
}
.MenuPlanning .MenuCards button {
  background-color: white;
  position: relative;
  float: right;
  margin-right: 10px;
  color: #f5ba13;
  border: none;
  width: 72px;
  height: 36px;
  cursor: pointer;
  outline: none;
}

.MenuPlanning .mobile-first {
  display: block;
}

.MenuPlanning .large-screen {
  display: none;
}

@media screen and (min-width: 600px) {
  .MenuPlanning .mobile-first {
    display: none;
  }
  .MenuPlanning .large-screen {
    display: block;
  }
}

.MenuPlanning form.createMenu {
  position: relative;
  /* max-width: 480px; */
  margin: 10px auto 20px auto;
  background: #fff;
  padding: 15px;
  border-radius: 7px;
  box-shadow: 0 1px 5px rgb(138, 137, 137);
}
.MenuPlanning form.createMenu input {
  width: 100%;
  border: none;
  padding: 4px;
  /* outline: none; */
  font-size: 1.2em;
  font-family: inherit;
  resize: none;
}


.MenuPlanning form.createMenu .muiToggleButtonClasses button {
  position: relative;
  background: #fff;
  color: #000;
  border: none;
  border-radius: 8px;
  width: 30px;
  height: 30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  outline: none;
  margin-right: 0px;
}

.MenuPlanning form.createMenu button {
  position: absolute;
  background: #f5ba13;
  color: #fff;
  border: none;
  border-radius: 18px;
  width: 80px;
  height: 36px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  outline: none;
}

.MenuPlanning form.createMenu button.saveButton {
  right: 120px;
  bottom: -18px;
}

.MenuPlanning form.createMenu button.deleteButton {
  right: 20px;
  bottom: -18px;
}

.MenuPlanning form.createMenu button.saveNewButton {
  background-color: darkorange;
  right: 120px;
  bottom: -18px;
}

.MenuPlanning form.createMenu button.cancelNewButton {
  background-color: darkorange;
  right: 20px;
  bottom: -18px;
}

.MenuPlanning form.create-menu {
  position: relative;
  width: 480px;
  margin: 30px auto 20px auto;
  background: #fff;
  padding: 15px;
  border-radius: 7px;
  box-shadow: 0 1px 5px rgb(138, 137, 137);
}

.MenuPlanning form.create-menu input,
.MenuPlanning form.create-menu textarea {
  width: 100%;
  border: none;
  padding: 4px;
  /* outline: none; */
  font-size: 1.2em;
  font-family: inherit;
  resize: none;
}

.MenuPlanning form.create-menu button {
  position: absolute;
  right: 18px;
  bottom: -18px;
  background: #f5ba13;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  outline: none;
}
.MenuPlanning form.createMenu .circle-button button{
  position: absolute;
  right: 0px;
  bottom: 118px;
  background: #f5ba13;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  outline: none;
}
.MenuPlanning .react-datepicker__navigation-icon {
  top: 5px;
  left: 0px;
}


.MenuPlanning .MuiBox-root {
  margin: 0 auto;
}

.MenuPlanning .FancyButton {
  background-color: tan;
}


.MenuPlanning .react-calendar {
  width: 100%;
}