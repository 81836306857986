
.Members {
  background-color: #c0c0c0;
  background-image: url("../img/patterns/cubes.png");
  /* covers the area with ONE tile */
  /* background-size: cover; */
  /* please no, we do NOT want a scroll-bar to appear */
  /* overflow-y: auto; */
  margin: 0;
  /* padding: 0 16px; */
  padding: 0;
}

.Members header {
  background-color: #f5ba13;
  /* margin: auto -16px; */
  padding: 16px 32px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
}

.Members header h1 {
  color: #fff;
  font-family: "McLaren", cursive;
  font-weight: 200;
}

.Members footer {
  position: sticky;
  text-align: center;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
}

.Members footer p {
  color: #ccc;
}

.Members .jumbotron {
  text-align: center;
  background-color: #efcb6a;
  border-radius: 5px;
  /* width: 90%; */
  /* width: 100%; */
  margin: 0px 20px;
  /* padding: 0px 20px; */
  /* padding-top: 20px; */
}

.Members .MenuCard {
  background: #fff;
  border-radius: 7px;
  box-shadow: 0 2px 5px #ccc;
  padding: 10px;
  min-width: 240px;
  width: 100%;
  /* max-width: 440px; */
  margin: 16px;
  float: left;
  white-space: nowrap;
}
.Members .MenuCard h1 {
  font-size: 1.1em;
  margin-bottom: 6px;
}
.Members .MenuCard p {
  font-size: 1.1em;
  margin-bottom: 10px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.Members .MenuCards {
  /* color: #ccc; */
  color: #888;
  margin: 0 20px;
  /* margin: 0 auto;
  width: 90%; */
}
.Members .MenuCards:after {
  content:"";
  clear:both;
  display:block;
}
.Members .MenuCards button {
  background-color: white;
  position: relative;
  float: right;
  margin-right: 10px;
  color: #f5ba13;
  border: none;
  width: 72px;
  height: 36px;
  cursor: pointer;
  outline: none;
}

.Members .mobile-first {
  display: block;
}

.Members .large-screen {
  display: none;
}

@media screen and (min-width: 600px) {
  .Members .mobile-first {
    display: none;
  }
  .Members .large-screen {
    display: block;
  }
}

.Members form.membersList {
  position: relative;
  /* max-width: 480px; */
  margin: 10px auto 20px auto;
  background: #fff;
  padding: 15px;
  border-radius: 7px;
  box-shadow: 0 1px 5px rgb(138, 137, 137);
}

.Members form.membersList input {
  width: 100%;
  border: none;
  padding: 4px;
  /* outline: none; */
  font-size: 1.2em;
  font-family: inherit;
  resize: none;
}

.Members form.membersList .muiToggleButtonClasses button {
  position: relative;
  background: #fff;
  color: #000;
  border: none;
  border-radius: 8px;
  width: 30px;
  height: 30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  outline: none;
  margin-right: 0px;
}

.Members form.membersList button {
  position: absolute;
  background: #f5ba13;
  color: #fff;
  border: none;
  border-radius: 18px;
  width: 80px;
  height: 36px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  outline: none;
}

.Members form.membersList button.saveButton {
  right: 120px;
  bottom: -18px;
}

.Members form.membersList button.deleteButton {
  right: 20px;
  /* bottom: -18px; */
  bottom: +18px;
}
