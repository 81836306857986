.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-Caution-Work-In-Progress {
  height: 20vmin;
  pointer-events: none;
  border-radius: 20px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.xlistItem{
  background-color: #f5f5f5;

}
.Box {
  color: inherit;
  /* border: red solid 0.5pt; */
  /* background-color: #0d4d5f; */
}

.RowWithGrowingExtendedListItem {
  border: green solid 0.5pt;
  /* background-color: #61dafb; */
  border-radius: 5pt;
}

.GrowingList {
  margin: 0pt;
  padding: 5pt;
  background-color: #f5f5f5;
  /* background-color: rgb(186, 186, 186); */
  border-radius: 5pt;
  /* border: red 1pt solid; */
}

.GrowingListItem {
  background-color: #f5f5f5;
  border-radius: 5pt;
  padding: 0 0 0 5pt;
  margin: 2pt;
  /* border: green 1pt solid; */
}

.GrowingExtendedListItem {
  width: 80%;
  border: black solid 0.5pt;
  border-radius: 5pt;
  /* list-style: none; */
  float: left;
  /* backgroundColor: "#fafafa", */
  /* background-color: #a8ccd6; */
  background-color: #f5f5f5;
  border-radius: 5pt;
  padding: 0 0 0 5pt;
  /* minHeight: "30pt", */
  margin: 5pt;
  /* opacity: 0.5; */
  /* border: green 1pt solid; */
}

.FullWidthGrowingExtendedListItem {
  width: 98%;
  border: black solid 0.5pt;
  border-radius: 5pt;
  /* list-style: none; */
  float: left;
  /* backgroundColor: "#fafafa", */
  /* background-color: #a8ccd6; */
  background-color: #f5f5f5;
  border-radius: 5pt;
  padding: 5pt;
  /* minHeight: "30pt", */
  margin: 5px;
  /* opacity: 0.5; */
  /* border: green 1pt solid; */
}